import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Platform } from "react-native";

function App() {
  const os = Platform.OS; // This will return "web"
  var device = ""
  if (os === 'web') {
    // Use the browser's user agent to detect the OS
    const userAgent = navigator.userAgent;
  
    if (/android/i.test(userAgent)) {
      device = "android";
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      device = "apple";
    } else if (/Macintosh/.test(userAgent)) {
      device = "apple";
    } else if (/Windows/.test(userAgent)) {
      device = "android";
    } else if (/Linux/.test(userAgent)) {
      device = "android";
    } else {
      device = "android";
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="alzheimerscom" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/alz-fundraising/id518217803" : "https://play.google.com/store/apps/details?id=com.nuclavis.alzheimers") } />
          <Route path="rospark" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/my-icecycle/id1659201886" : "https://play.google.com/store/apps/details?id=com.nuclavis.rospark") } />
          <Route path="roswellride" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/myrfr/id1448722112" : "https://play.google.com/store/apps/details?id=com.nuclavis.rideforroswell") } />
          <Route path="ahayouthmarket" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/aha-schools/id548389448" : "https://play.google.com/store/apps/details?id=com.aha_jump.android.communicator") } />
          <Route path="ahaheartwalk" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/heart-walk/id451276834" : "https://play.google.com/store/apps/details?id=com.aha.hw.communicator") } />
          <Route path="ahacyclenation" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/cyclenation/id1155979191" : "https://play.google.com/store/apps/details?id=com.aha.cyclenation") } />
          <Route path="nationalkidney" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/kidney-walk/id1510859504" : "https://play.google.com/store/apps/details?id=com.nuclavis.nationalkidney") } />
          <Route path="makeawish" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/make-a-wish-fundraising/id6717612777" : "https://play.google.com/store/apps/details?id=com.nuclavis.makeawish") } />
          <Route path="autismcom" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/autism-speaks-fundraising/id1282101691" : "https://play.google.com/store/apps/details?id=com.charitydynamics.aswalk") } />
          <Route path="nami" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/fundraise4nami/id6478622344" : "https://play.google.com/store/apps/details?id=com.nuclavis.nami") } />
          <Route path="childrensnational" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/race-for-every-child/id1477493057" : "https://play.google.com/store/apps/details?id=com.nuclavis.childrensnational") } />
          <Route path="ahawomenofimpact" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/woman-of-impact/id6740215828" : "https://play.google.com/store/apps/details?id=com.nuclavis.ahawomenofimpact") } />
          <Route path="ahaleadersofimpact" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/leaders-of-impact/id6740215425" : "https://play.google.com/store/apps/details?id=com.nuclavis.ahaleadersofimpact") } />
          <Route path="ahateenofimpact" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/teen-of-impact/id6740215978" : "https://play.google.com/store/apps/details?id=com.nuclavis.ahateenofimpact") } />
          <Route path="cff" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/cure-cff/id646567015" : "https://play.google.com/store/apps/details?id=com.nuclavis.cff") } />
          <Route path="shatterproof" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/shatterproof-fundraising/id6743419369" : "http://play.google.com/store/apps/details?id=com.nuclavis.shatterproof") } />
          <Route path="breakthrough" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/breakthrough-t1d-fundraising/id6742032879" : "https://play.google.com/store/apps/details?id=com.nuclavis.breakthrough") } />
          <Route path="abilityexperience" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/abilityexp-fundraising/id6740108059" : "https://play.google.com/store/apps/details?id=com.nuclavis.abilityexperience") } />
          <Route path="expectmiracles" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/emf-distance-challenge/id6741597017" : "https://play.google.com/store/apps/details?id=com.nuclavis.expectmiracles") } />
          <Route path="cityofhope" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/walk-for-hope/id6499440284" : "https://play.google.com/store/apps/details?id=com.nuclavis.cityofhope") } />
          <Route path="foundationwomenscancer" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/support-fwc/id6742595275" : "https://play.google.com/store/apps/details?id=com.nuclavis.foundationwomenscancer") } />
          <Route path="parkinsons" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/fundraise-for-parkinsons/id6720756441" : "https://play.google.com/store/apps/details?id=com.nuclavis.parkinsons") } />
          <Route path="lustgarten" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/active-with-lustgarten/id1671062640" : "https://play.google.com/store/apps/details?id=com.nuclavis.lustgarten") } />
          <Route path="wwp" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/carry-forward/id1546138717" : "https://play.google.com/store/apps/details?id=com.charitydynamics.wwp") } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
